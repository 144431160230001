<template>
  <layout>
    <template #header>
      <app-header
        color="white"
        :showMobileMenuButton="false"
        :showMobileLogo="false"
      >
        <template #left>
          <router-link to="/system-admin/contents">
            <outline-button color="#777A7A">Cancel</outline-button>
          </router-link>
        </template>
        <template #right>
          <div class="right-button-list">
            <outline-button class="mx-3" @click="triggerPreviewContent"
              >Preview</outline-button
            >
            <inqli-button @click="publish">Publish</inqli-button>
          </div>
        </template>
      </app-header>
    </template>
    <h1 class="my-5">Create new content</h1>
    <v-card class="container" rounded="xl">
      <content-form @submit="createContent" @preview="previewContent" />
      <h3 class="section-title">Relationships</h3>
      <organization-select v-model="org" />

      <user-select v-model="user" :org-id="org.id" />
    </v-card>
    <content-preview
      v-model="showPreview"
      :content="content"
      :author="user"
      @publish="publish"
    />
  </layout>
</template>
<script>
import Header from "../../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../../app/components/layouts/DefaultLayout";

import Button from "../../../app/components/buttons/Button";
import OutlineButton from "../../../app/components/buttons/OutlineButton";

import ContentForm from "../components/ContentForm";
import UserSelect from "../components/UserSelect";
import OrganizationSelect from "../components/OrganizationSelect.vue";
import ContentPreview from "../components/ContentPreview";
import EventBus from "../event-bus.js";
import { createContent } from "./content-create.js";

export default {
  name: "ContentEdit",
  metaInfo: {
    title: "Create content",
  },
  components: {
    "app-header": Header,
    layout: DefaultLayout,
    "inqli-button": Button,
    "outline-button": OutlineButton,
    "content-form": ContentForm,
    "user-select": UserSelect,
    "content-preview": ContentPreview,
    "organization-select": OrganizationSelect,
  },
  data() {
    return {
      user: {},
      org: {},
      showPreview: false,
      content: null,
    };
  },
  methods: {
    publish() {
      EventBus.$emit("submit");
    },
    triggerPreviewContent() {
      EventBus.$emit("preview");
    },
    previewContent(content) {
      if (!this.user.id) {
        return;
      }
      this.showPreview = true;
      this.content = content;
    },
    async createContent(content, image) {
      if (!this.user.id || !this.org.id) {
        return;
      }
      try {
        const createdContent = await createContent({
          content,
          authorId: this.user.id,
          orgId: this.org.id,
          image,
        });
        if (createdContent) {
          this.$router.push("/contents/" + createdContent.id);
        }
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while creating content"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 15px 48px;
  width: 75%;
  max-width: 814px;
  margin: 10px auto;
}
.button-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.right-button-list {
  display: flex;
}
.section-title {
  margin-top: 10px;
}
@media (max-width: $breakpoint-tablet-large) {
  .container {
    padding: 10px 30px;
    width: 95%;
  }
}
@media (max-width: $breakpoint-mobile) {
  .container {
    padding: 10px 15px;
    width: 95%;
  }
}
</style>
