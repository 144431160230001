import { render, staticRenderFns } from "./UserSelect.vue?vue&type=template&id=8bf78800&"
import script from "./UserSelect.vue?vue&type=script&lang=js&"
export * from "./UserSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VChip,VImg,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8bf78800')) {
      api.createRecord('8bf78800', component.options)
    } else {
      api.reload('8bf78800', component.options)
    }
    module.hot.accept("./UserSelect.vue?vue&type=template&id=8bf78800&", function () {
      api.rerender('8bf78800', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/system-admin/contents/components/UserSelect.vue"
export default component.exports