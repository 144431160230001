<template>
  <autocomplete-select
    label="Select user"
    v-model="user"
    item-text="display_name"
    :error-messages="errorMessage"
    :update-items="getUserList"
  >
    <template v-slot:selection="{ data }">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        color="transparent"
      >
        <v-avatar left>
          <v-img :src="data.avatar"></v-img>
        </v-avatar>
        {{ data.display_name }}
      </v-chip>
    </template>
    <template v-slot:item="{ data }">
      <template v-if="typeof data !== 'object'">
        <v-list-item-content v-text="data"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.display_name"></v-list-item-title>
          <v-list-item-subtitle v-html="data.org"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </autocomplete-select>
</template>
<script>
import sendRequest from "../../../app/utils/send-request-helper";
import EventBus from "../event-bus.js";
import AutoCompleteSelect from "../../../app/components/inputs/AutoCompleteSelect.vue";

export default {
  name: "UserSelect",
  data() {
    return {
      errorMessage: "",
      limit: 10,
    };
  },
  components: {
    "autocomplete-select": AutoCompleteSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    orgId: {
      type: String,
    },
  },
  async mounted() {
    EventBus.$on("submit", this.validate);
    EventBus.$on("preview", this.validate);
  },
  computed: {
    user: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (val && val.id) {
          this.errorMessage = "";
        }
      },
    },
  },
  watch: {
    orgId() {
      // reset user and user list
      this.user = {};
    },
  },
  methods: {
    validate() {
      if (!this.user.id) {
        this.errorMessage = "User is required";
      }
    },
    async getUserList(currentPage) {
      try {
        const orgId = this.orgId;
        const limit = this.limit;
        let data;
        if (orgId) {
          data = await sendRequest({
            url: `/relationships/member?dest_id=${orgId}&page=${currentPage}&limit=${limit}`,
            method: "GET",
            isAuth: true,
          });
        } else {
          data = {
            list: [],
            total: 0,
            total_pages: 0,
          };
        }
        return data;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting user list"
        );
      }
    },
  },
};
</script>
