<template>
  <autocomplete-select
    label="Select organization"
    v-model="org"
    item-text="name"
    :error-message="errorMessage"
    :limit="limit"
    :update-items="getOrgList"
  >
    <template v-slot:selection="{ data }">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        color="transparent"
      >
        <v-avatar left>
          <v-img :src="data.logo_url"></v-img>
        </v-avatar>
        {{ data.name }}
      </v-chip>
    </template>
    <template v-slot:item="{ data }">
      <template v-if="typeof data !== 'object'">
        <v-list-item-content v-text="data"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.logo_url" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.name"></v-list-item-title>
          <v-list-item-subtitle v-html="data.type"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </autocomplete-select>
</template>
<script>
import sendRequest from "../../../app/utils/send-request-helper";
import EventBus from "../event-bus.js";
import AutoCompleteSelect from "../../../app/components/inputs/AutoCompleteSelect.vue";

export default {
  name: "OrganizationSelect",
  components: {
    "autocomplete-select": AutoCompleteSelect,
  },
  data() {
    return {
      errorMessage: "",
      limit: 10,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    EventBus.$on("submit", this.validate);
    EventBus.$on("preview", this.validate);
  },
  computed: {
    org: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (val && val.id) {
          this.errorMessage = "";
        }
      },
    },
  },
  methods: {
    validate() {
      if (!this.org.id) {
        this.errorMessage = "Organization is required";
      }
    },
    async getOrgList(currentPage) {
      try {
        const limit = this.limit;
        const data = await sendRequest({
          url: `${process.env.VUE_APP_URL}/resources/organizations?page=${currentPage}&limit=${limit}`,
          method: "GET",
          isAuth: true,
        });
        return data;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting organization list"
        );
      }
    },
  },
};
</script>
