import sendRequest from "../../../app/utils/send-request-helper";
import uploadImage from "../../../app/utils/upload-image-helper";

export const createContent = async ({ content, authorId, image, orgId }) => {
  if (!authorId || authorId === "" || !orgId || orgId === "") {
    return;
  }
  let imageUrl = "";
  if (image && image != null) {
    imageUrl = await uploadImage(image);
  }
  const data = await sendRequest({
    url: `${process.env.VUE_APP_URL}/resources/contents?author_id=${authorId}&organization_id=${orgId}`,
    method: "POST",
    isAuth: true,
    body: {
      ...content,
      expiry_date: "",
      date: "",
      image_url: imageUrl,
    },
  });
  return data;
};
