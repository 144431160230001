<template>
  <div>
    <v-autocomplete
      :label="label"
      v-model="selectedItem"
      :items="items"
      chips
      item-text="name"
      return-object
      :error-messages="errorMessage"
    >
      <template v-slot:selection="{ item }">
        <slot name="selection" :data="item"></slot>
      </template>
      <template v-slot:item="{ item }">
        <slot name="item" :data="item"></slot>
      </template>
      <template v-slot:append-item>
        <div
          v-intersect="onIntersect"
          class="pa-4 teal--text"
          v-if="currentPage < totalPages"
        >
          Loading more items...
        </div>
        <div v-else class="pa-4 teal--text">No more items to load.</div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * Show suggested list for user to select
 * Can not create new item from the input
 * Dedicated to interact with paginated endpoints
 * @props 'items': array of items suggested to select
 * @props 'item-text': text to display in the item value
 * @props 'label': label of the input
 * @v-model: selected item
 * @props 'update-items' - update the list of items - arg: currentPage and totalPages
 * @reference https://vuetifyjs.com/en/api/v-autocomplete
 * @note this is just a initial version, still need more work on frontend and backend to improve research results displayed
 */
export default {
  name: "AutoCompleteSelect",
  data() {
    return {
      currentPage: 1,
      items: [],
      totalPages: 10,
    };
  },
  props: {
    limit: {
      type: Number,
      default: 10,
    },
    value: {
      type: Object,
      required: true,
    },
    updateItems: {
      type: Function,
      required: true,
    },
    itemText: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  async mounted() {
    await this.getItemList();
  },
  watch: {
    async value(value) {
      if (!value.id) {
        await this.getItemList();
      }
    },
  },
  methods: {
    async onIntersect(entries) {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting && this.currentPage < this.totalPages) {
          this.currentPage++;
          const result = await this.updateItems(this.currentPage);
          this.items = [...this.items, ...result.list];
        }
      });
    },
    async getItemList() {
      const result = await this.updateItems(this.currentPage);
      this.items = result.list;
      this.totalPages = result.total_pages;
    },
  },
};
</script>
