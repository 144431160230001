var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        attrs: {
          label: _vm.label,
          items: _vm.items,
          chips: "",
          "item-text": "name",
          "return-object": "",
          "error-messages": _vm.errorMessage
        },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                return [_vm._t("selection", null, { data: item })]
              }
            },
            {
              key: "item",
              fn: function(ref) {
                var item = ref.item
                return [_vm._t("item", null, { data: item })]
              }
            },
            {
              key: "append-item",
              fn: function() {
                return [
                  _vm.currentPage < _vm.totalPages
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "intersect",
                              rawName: "v-intersect",
                              value: _vm.onIntersect,
                              expression: "onIntersect"
                            }
                          ],
                          staticClass: "pa-4 teal--text"
                        },
                        [_vm._v(" Loading more items... ")]
                      )
                    : _c("div", { staticClass: "pa-4 teal--text" }, [
                        _vm._v("No more items to load.")
                      ])
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedItem,
          callback: function($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }