var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("autocomplete-select", {
    attrs: {
      label: "Select organization",
      "item-text": "name",
      "error-message": _vm.errorMessage,
      limit: _vm.limit,
      "update-items": _vm.getOrgList
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var data = ref.data
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  attrs: { "input-value": data.selected, color: "transparent" }
                },
                "v-chip",
                data.attrs,
                false
              ),
              [
                _c(
                  "v-avatar",
                  { attrs: { left: "" } },
                  [_c("v-img", { attrs: { src: data.logo_url } })],
                  1
                ),
                _vm._v(" " + _vm._s(data.name) + " ")
              ],
              1
            )
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var data = ref.data
          return [
            typeof data !== "object"
              ? [
                  _c("v-list-item-content", {
                    domProps: { textContent: _vm._s(data) }
                  })
                ]
              : [
                  _c("v-list-item-avatar", [
                    _c("img", { attrs: { src: data.logo_url } })
                  ]),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { innerHTML: _vm._s(data.name) }
                      }),
                      _c("v-list-item-subtitle", {
                        domProps: { innerHTML: _vm._s(data.type) }
                      })
                    ],
                    1
                  )
                ]
          ]
        }
      }
    ]),
    model: {
      value: _vm.org,
      callback: function($$v) {
        _vm.org = $$v
      },
      expression: "org"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }