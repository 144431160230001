import { render, staticRenderFns } from "./ContentCreate.vue?vue&type=template&id=39583646&scoped=true&"
import script from "./ContentCreate.vue?vue&type=script&lang=js&"
export * from "./ContentCreate.vue?vue&type=script&lang=js&"
import style0 from "./ContentCreate.vue?vue&type=style&index=0&id=39583646&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39583646",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39583646')) {
      api.createRecord('39583646', component.options)
    } else {
      api.reload('39583646', component.options)
    }
    module.hot.accept("./ContentCreate.vue?vue&type=template&id=39583646&scoped=true&", function () {
      api.rerender('39583646', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/system-admin/contents/content-create/ContentCreate.vue"
export default component.exports