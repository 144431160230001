var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("app-header", {
                attrs: {
                  color: "white",
                  showMobileMenuButton: false,
                  showMobileLogo: false
                },
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function() {
                      return [
                        _c(
                          "router-link",
                          { attrs: { to: "/system-admin/contents" } },
                          [
                            _c(
                              "outline-button",
                              { attrs: { color: "#777A7A" } },
                              [_vm._v("Cancel")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "right",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "right-button-list" },
                          [
                            _c(
                              "outline-button",
                              {
                                staticClass: "mx-3",
                                on: { click: _vm.triggerPreviewContent }
                              },
                              [_vm._v("Preview")]
                            ),
                            _c("inqli-button", { on: { click: _vm.publish } }, [
                              _vm._v("Publish")
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("h1", { staticClass: "my-5" }, [_vm._v("Create new content")]),
      _c(
        "v-card",
        { staticClass: "container", attrs: { rounded: "xl" } },
        [
          _c("content-form", {
            on: { submit: _vm.createContent, preview: _vm.previewContent }
          }),
          _c("h3", { staticClass: "section-title" }, [_vm._v("Relationships")]),
          _c("organization-select", {
            model: {
              value: _vm.org,
              callback: function($$v) {
                _vm.org = $$v
              },
              expression: "org"
            }
          }),
          _c("user-select", {
            attrs: { "org-id": _vm.org.id },
            model: {
              value: _vm.user,
              callback: function($$v) {
                _vm.user = $$v
              },
              expression: "user"
            }
          })
        ],
        1
      ),
      _c("content-preview", {
        attrs: { content: _vm.content, author: _vm.user },
        on: { publish: _vm.publish },
        model: {
          value: _vm.showPreview,
          callback: function($$v) {
            _vm.showPreview = $$v
          },
          expression: "showPreview"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }